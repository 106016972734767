import React, { useState, useEffect } from 'react';

import './App.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { color, motion, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import leftArrowImage from './assets/images/left-arrow.webp';
import rightArrowImage from './assets/images/right-arrow.webp';
import bgDecoration from './assets/images/background-decoration.webp'
import fgDecoration from './assets/images/foreground-decoration.webp'
import blogDecoration from './assets/images/blog-decoration.webp'
import ScrollingCordon from './assets/images/cordon.webp';
import logo from './assets/images/mm_logo3.svg'
import blogWallArt from './assets/images/blog_wall_art.webp'
import homeScreenArt from './assets/images/home-screen-art.webp'
import sticker from './assets/images/sticker.webp'
import arrow from './assets/images/arrow.webp'

import { useWindowScroll } from 'react-use';



import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';




import CustomCarousel from './CustomCarousel';
import 'react-alice-carousel/lib/alice-carousel.css';



import "react-responsive-carousel/lib/styles/carousel.min.css";



function App() {
  
  

  const blogImages = [
    "https://res.cloudinary.com/dxhpsqke1/image/upload/v1701809656/streetfighter_jiuwyb.webp",
    "https://res.cloudinary.com/dxhpsqke1/image/upload/v1701809656/arGlasses_umqckr.webp"
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % blogImages.length);
    }, 5000); 

    return () => clearInterval(intervalId); 
  }, [blogImages.length]);






  const titlesAndDescriptions = [
    {
      title: "co-lab",
      description: "This was a lot of fun. A big highlight for me was getting handtracking in, in time! co-lab was built to bring creatives together to collaborate on artistic projects like 3D sculpting, musical expression, story writing, and visual art.",
    },
    {
      title: "Fellowship Finder",
      description: "FellowShip Finder is an lfg (looking for group) but for real life! The main game in mind during development was Dungeons and Dragons, but it can be used for any board game or roleplaying game that requires a group.",
    },
    {
      title: "HeadStrong",
      description: "Headstrong is designed to provide a relaxing, stress-free environment to write out thoughts. This project dealt with a legacy codebase so it was fun matching the original developers intent.",
    },
    
  ];


  




  
  

  const readMoreBlog1 = () => {
    // Handle the click event, e.g., redirect to a specific URL
    window.open("https://medium.com/@MarvasMcCladdie/ar-glasses-a-technical-overview-ac6c217537a2");
  };
  const readMoreBlog2 = () => {
    // Handle the click event, e.g., redirect to a specific URL
    window.open("https://medium.com/@MarvasMcCladdie/can-fighting-games-make-you-a-better-programmer-ef5d20fdfbe1");
  };
  
  const readMoreBlog3 = () => {
    // Handle the click event, e.g., redirect to a specific URL
    window.open("https://medium.com/@MarvasMcCladdie/star-citizen-is-one-of-the-most-ambitious-and-highly-anticipated-video-games-of-all-time-486abb0a02a9");
  };


  const [bgImageIndex, setBgImageIndex] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

 



  
  const handleSlideChange = (index: React.SetStateAction<number>) => {
    setCurrentSlideIndex(index);
  };






  const [threshold, setThreshold] = useState(300); // Adjust the threshold as needed

  // Create a motion value for the scroll position
  const scrollY = useMotionValue(0);

  // Create a transform to move the nav-decoration off the page
  const navDecorationY = useTransform(scrollY, [100, 300], ['0%', '-50%']);

  // Update the scrollY value when the user scrolls
const handleScroll = () => {
  const scrollPosition = window.scrollY;
  scrollY.set(scrollPosition);

  // Check if the scroll position exceeds the threshold
  if (scrollPosition >= threshold) {
    const newY = -50 - (scrollPosition - threshold) * 0.1; // Adjust the multiplier as needed
    navDecorationY.set(`${newY}%`);
  } else {
    navDecorationY.set('-50%');
  }
};

useEffect(() => {
  // Attach the scroll event listener
  window.addEventListener('scroll', handleScroll);

  // Clean up the event listener when the component unmounts
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);



const [cordonX, setCordonX] = useState(0);

const { y: cordonScrollY } = useWindowScroll();

useEffect(() => {
  // You can adjust the multiplier to control the scroll speed
  const scrollMultiplier = 0.5;
  setCordonX(-cordonScrollY * scrollMultiplier);
}, [cordonScrollY]);













const cloudImages = [
  "https://res.cloudinary.com/dxhpsqke1/image/upload/v1704532366/tsconfig-copy-0_rb4xbc.jpg",
  "https://res.cloudinary.com/dxhpsqke1/image/upload/v1704532367/tsconfig_qjjn2i.jpg",
  "https://res.cloudinary.com/dxhpsqke1/image/upload/v1704532366/headstronggg_mh9hfa_v1yvkz.png",
];






const formattedCloudImages = cloudImages.map((url, index) => ({
  src: url,
  caption: `Image ${index + 1}`,
  style: {
    borderRadius: '10%',
  },
}));









  return (
    <>
      
      <motion.div className='nav-decoration' style={{ y: navDecorationY }}>
        
      </motion.div> 
           


      <div className="nav-container">
  <div id='logo'>
    <img src={logo} alt="Marvas McCladdie Logo" className="logo-img" />
  </div>

  <div className="navbar">
    <div className="links">
      <a href="https://docs.google.com/document/d/1R0t6ZQMQESlONvYhtk592QrCr-qKfZedKDR5dd2fujI/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
        Resume
      </a>
      <a href="https://www.linkedin.com/in/marvasmc/" target="_blank" rel="noopener noreferrer">
        LinkedIn
      </a>
      <a href="https://github.com/" target="_blank" rel="noopener noreferrer">
        Github
      </a>
      <a href="https://mail.google.com/mail/?view=cm&fs=1&to=marvasmc@gmail.com&su=Job%20Application&body=" target="_blank" rel="noopener noreferrer">
        Email
      </a>
    </div>
  </div>
</div>




       

      <div className='sticker'>
        <img  
          src={sticker} 
          alt="" 
        />
        
      


      </div>


      <div className='arrow-div'>
      <a href="https://mail.google.com/mail/?view=cm&fs=1&to=marvasmc@gmail.com&su=Job%20Application&body=" target="_blank" rel="noopener noreferrer" className='arrow' >
            <img src={arrow} alt="" className='pulsate' />
        </a>
      </div>

      <div className='test1'>
          
        <h1 className='test2'>
          {`Hi 👋, I'm Marvas`} <br /> 
        </h1>
        <p className='test3'> 
          {`A Frontend focused web developer, passionate about embracing
          new technologies; I'm seeking a position that will allow me to apply and further develop my skills.`} <br /> 
          {`Reach out via email for more information!`}
          <br />
          <a style={{ color: '#0267ff' }} className="email" href="https://mail.google.com/mail/?view=cm&fs=1&to=marvasmc@gmail.com&su=Job%20Application&body=" target="_blank" rel="noreferrer">
    marvasmc@gmail.com
</a>


          
        </p>
        </div>
      

     
      <div className='home-screen-container'>
        
        <img 
          src='https://res.cloudinary.com/dxhpsqke1/image/upload/v1706304786/home-screen-artt_uojaav.webp'
          alt="" 
          className='home-screen-art' 
        /> 

       
        
      
      </div>

      

      

      <div className="project-container">

        <div className='bg-dec' >
          <img
                src={bgDecoration}
                alt=""
                className='background-decoration'
          />
        </div>

        <div className='fg-dec' >
          <img
                src={fgDecoration}
                alt=""
                className='foreground-decoration'
                
          />
         </div>

        

        
        <div className='project-background' >
          <img
            src={formattedCloudImages[currentSlideIndex].src}
            alt=""
            className='project-background-art'
          />
        </div>

  
        
        <div className='project-blurb'>
          
          <h1 className='project-title' >{titlesAndDescriptions[currentSlideIndex].title}</h1>
          <p className='project-desc'>{titlesAndDescriptions[currentSlideIndex].description}</p>
        </div>




      <div className='blg-wall-art'>
        <img 
          src={blogWallArt} 
          alt="" 
          className='blog-wall-art'
        
        />

<motion.div className='cordon1' style={{
            backgroundPositionX: cordonX,
          }}>
          
        </motion.div>


        <motion.div className='cordon2' style={{
            backgroundPositionX: cordonX,
            
          }}>
          
        </motion.div>

        <motion.div className='cordon3' style={{
            backgroundPositionX: cordonX,
            
          }}>
          
        </motion.div>

      </div>
      

      <div className='blg-decoration'>
          <img
              src={blogDecoration}
              alt=""
              className='blog-decoration'

          />
      </div>
      

      <div className="blog-bg"> 
        <img 
          src={blogImages[currentIndex]}
          alt="" 
          className='blog-background'
        />
      </div>
      <div className='blog-container'>
          <div id="container">
              <button className="read-more" onClick={readMoreBlog1}>
                <span className="circle" aria-hidden="true">
                  <span className="icon arrow"></span>
                </span>
                <div className="button-text">
                  Read More
                </div>
              </button>
              <div className='blog-title' onClick={readMoreBlog1}>
                <h4>Ar Glasses</h4>
              
              </div>
              
            </div>
            <div id="container">
              <button className="read-more" onClick={readMoreBlog2}>
                <span className="circle" aria-hidden="true">
                  <span className="icon arrow"></span>
                </span>
                <div className="button-text">
                  Read More
                </div>
              </button>
              <div className='blog-title' onClick={readMoreBlog2}>
                <h4>Can Fighting Games Make You A Better Programmer?</h4>
              
              </div>
              
            </div>
            <div id="container">
              <button className="read-more" onClick={readMoreBlog3}>
                <span className="circle" aria-hidden="true">
                  <span className="icon arrow"></span>
                </span>
                <div className="button-text">
                  Read More
                </div>
              </button>
              <div className='blog-title' onClick={readMoreBlog3}>
                <h4>What is Star Citizens Persistent Entity Streaming</h4>
              
              </div>
              
            </div>
      </div>
        
      

        <> 
           <CustomCarousel
            buttonPosition="top"
            slidePosition="center"
            thumbnailPosition="bottom"
            items={formattedCloudImages}
            onSlideChange={handleSlideChange}
          /> 
        </>

      </div>
      
          
    </>
  );
}

export default App;



