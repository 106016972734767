
import leftArrowImage from './assets/images/left-arrow.png';
import rightArrowImage from './assets/images/right-arrow.png';




import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface CustomCarouselProps {
  buttonPosition: string;
  slidePosition: string;
  thumbnailPosition: string;
  items: { src: string; caption: string }[];
  onSlideChange: (index: number) => void;
}

const CustomCarousel: React.FC<CustomCarouselProps> = ({
  buttonPosition,
  slidePosition,
  thumbnailPosition,
  items,
  onSlideChange,
}) => {
  return (
    <Carousel
    // renderArrowPrev={(onClickHandler, hasPrev, label) =>
    //   hasPrev && (
    //     <button
    //       type="button"
    //       onClick={onClickHandler}
    //       title={label}
    //       className={`custom-arrow custom-arrow-prev inline-button`}
    //       // Set a class for inline positioning
    //       style={{
    //         backgroundImage: `url(${rightArrowImage})`,
    //         backgroundSize: 'cover',
    //         display: 'inline-block', 
    //         marginRight: '10px', 
    //         zIndex: 2, 
    //       }}
    //     >
    //       Prev
    //     </button>
    //   )
    // }
    // renderArrowNext={(onClickHandler, hasNext, label) =>
    //   hasNext && (
    //     <button
    //       type="button"
    //       onClick={onClickHandler}
    //       title={label}
    //       className={`custom-arrow custom-arrow-next inline-button`}
    //       // Set a class for inline positioning
    //       style={{
    //         backgroundImage: `url(${rightArrowImage})`,
    //         backgroundSize: 'cover',
    //         display: 'inline-block', 
    //         zIndex: 2, 
    //       }}
    //     >
    //       Next
    //     </button>
    //   )
    // }
      
      onChange={(index) => onSlideChange(index)}
      renderThumbs={(children: React.ReactNode[]) => {
        return React.Children.toArray(children).map((child, index) => (
          <div key={index} className={`custom-thumb ${thumbnailPosition}`}>
            {child}
          </div>
        ));
      }}
    >
      {items.map((item, index) => (
        <div key={index} className={`custom-slide ${slidePosition}`}>
          {/* Your slide content goes here */}
          <img src={item.src} alt={`Slide ${index + 1}`} />
        </div>
      ))}
    </Carousel>
  );
};

export default CustomCarousel;

